import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <h1>This site is under construction.</h1>
    <p>Want to get in touch? Reach out! 
    <br/>
    <br/>
    <a href="mailto:hello@unveiledleadership.com">Hello@unveiledleadership.com</a></p>
  </Layout>
)

export default IndexPage
